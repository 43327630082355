import { Box, Flex, Dropdown } from "@fluentui/react-northstar";
import Timer from "components/Timer";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Subscription } from "rxjs";
import { serviceRequests, socketAuth } from "services";
import { socketWrapUp } from "services/extendedWrapUp";
import { socketPresence } from "services/presence";
import { WrapStatus, StorageKeys } from "utils";
import { ExtendedWrapUpSettings } from "utils/domain/extendedWrapUpSettings";
import { Presence, PresenceAndNoteDTO } from "utils/domain/presence";
import { getApiPresenceForContactCenterPresence } from "./PresenceMap";
import Lockr from 'lockr';
import { useIntl } from 'react-intl';
import { initialClientLoadService } from "services/initialClientLoad";
import { TeamsClientInitSettingsDto } from "utils/domain/extended/teamsClientInitSettingsDto";
import { socketContacts } from "services/contacts";
import { useSubjectListenerEffect } from "utils/helpers/listenerHook";
import { GeneralHelper } from "utils/helpers";

const WrapUpExtendedView = () => {
    const [wrapUpSettingsState, setWrapUpSettings] = useState(undefined as ExtendedWrapUpSettings | undefined);
    const [searchQuery, setSearchQuery] = useState(wrapUpSettingsState?.DefaultAssignedWrapUpCode?.Name ?? "");
    const [selectedCodeQuery, setSelectedCodeQuery] = useState(wrapUpSettingsState?.DefaultAssignedWrapUpCode?.Name ?? "");
    const [allWrapUpCodes, setAllWrapUpCodes] = useState([] as any);
    const wrapUpSettingsRef = useRef(undefined as ExtendedWrapUpSettings | undefined);
    const currentTimeout = useRef<ReturnType<typeof setTimeout>>();
    const intl = useIntl();
    const currentUserId = useMemo<string>(() => Lockr.get<string>(StorageKeys.UserObjectId), []);

    const wrapUpStatusRef = useRef(WrapStatus.Ended);
    const initSettingsRequestCompleted = useRef(false);

    useSubjectListenerEffect((userPresence: Presence) => {
        if (userPresence.Id !== currentUserId) {
            return;
        }
        resetWrapUpSettings();
    }, socketContacts.notifyUpdatedPresenceState);

    let subscriptionSocketAuthUserVerified: Subscription | null = null;
    let subscriptionSocketPresenceSelect: Subscription | null = null;
    let subscriptionInitializeWrapUp: Subscription | null = null;
    let subscriptionStopWrapUp: Subscription | null = null;
    let subscriptionInitialSettingsRequestCompleted: Subscription | null = null;

    const componentWillUnmount = () => {
        subscriptionSocketAuthUserVerified?.unsubscribe();
        subscriptionSocketPresenceSelect?.unsubscribe();
        subscriptionInitializeWrapUp?.unsubscribe();
        subscriptionStopWrapUp?.unsubscribe();
        subscriptionInitialSettingsRequestCompleted?.unsubscribe();
    }

    useEffect(() => {
        initialize();

        return () => {
            componentWillUnmount();
        }
    }, []);

    useEffect(() => {
        if (wrapUpSettingsState && wrapUpSettingsState?.WrapUpTimeInterval && wrapUpSettingsState?.EndDate) {
            const serverClientTimeDifference = (Lockr.get<number>(StorageKeys.ServerClientTimeDifference) || 0);
            const timeoutInterval = wrapUpSettingsState.EndDate.getTime() - Date.now() - serverClientTimeDifference;

            if (timeoutInterval >= 0) {
                if (currentTimeout.current) {
                    clearTimeout(currentTimeout.current);
                }

                currentTimeout.current = setTimeout(() => {
                    resetWrapUpSettings();
                }, timeoutInterval);

                wrapUpStatusRef.current = WrapStatus.Started;

                const wrapUpPresenceAndNoteDto: Presence = {
                    Availability: getApiPresenceForContactCenterPresence(wrapUpSettingsState.Presence) ?? '',
                    Activity: wrapUpSettingsState.Reason,
                    IsWrapup: true
                };
                socketPresence.select.next(wrapUpPresenceAndNoteDto);

                processWrapUpCodes(wrapUpSettingsState)
            }
        }
        else {
            socketPresence.wrapUp.next(false);
        }
    }, [wrapUpSettingsState]);

    const processWrapUpCodes = (wrapUpSettings: ExtendedWrapUpSettings | undefined) => {
        if (!wrapUpSettings) {
            return;
        }

        let currentWrapUpCodeId = Lockr.get<string>(StorageKeys.CurrentWrapUpCodeId);
        if (!currentWrapUpCodeId) {
            const defaultWrapupCode = wrapUpSettings?.DefaultAssignedWrapUpCode;
            currentWrapUpCodeId = defaultWrapupCode?.Id ?? "";
            Lockr.set(StorageKeys.CurrentWrapUpCodeId, currentWrapUpCodeId);
            const currentWrapUpCodeName = defaultWrapupCode?.Name ?? "";
            Lockr.set(StorageKeys.CurrentWrapUpCodeName, currentWrapUpCodeName);
        }

        const wrapUpCodesToDisplay = mapWrapUpCodesListForDisplay(wrapUpSettings.AllWrapUpCodesFromCollections,
            wrapUpSettings.DefaultAssignedWrapUpCode,
            currentWrapUpCodeId,
            wrapUpSettings.IsLevelOneHidden);

        setAllWrapUpCodes(wrapUpCodesToDisplay);

        let currentSearchQuery = null;
        if (currentWrapUpCodeId) {
            currentSearchQuery = wrapUpCodesToDisplay.filter((x: any) => x.key === currentWrapUpCodeId)[0]?.header;
        }

        setSearchQuery(currentSearchQuery ? currentSearchQuery : wrapUpSettings?.DefaultAssignedWrapUpCode?.Name ?? "");
        setSelectedCodeQuery(currentSearchQuery ? currentSearchQuery : wrapUpSettings?.DefaultAssignedWrapUpCode?.Name ?? "");
    }

    const mapWrapUpCodesListForDisplay = (wrapUpCodes: any, defaultWrapUpCodeId: any, currentWrapUpCode: any, isLevelOneHidden: boolean | undefined = undefined) => {

        const wrapUpCodesForDisplay = [] as any;
        wrapUpCodes.forEach((wrapUpCode: any) => {
            if (!isLevelOneHidden || (isLevelOneHidden && wrapUpCode.Level > 1)) {
                const selectedValue = defaultWrapUpCodeId ? defaultWrapUpCodeId.Id === wrapUpCode.Id : false;
                const currentValue = currentWrapUpCode ? currentWrapUpCode === wrapUpCode.Id : selectedValue;
                wrapUpCodesForDisplay.push(
                    {
                        header: wrapUpCode.Name,
                        selected: currentValue,
                        title: wrapUpCode.Name,
                        key: wrapUpCode.Id,
                        styles: {
                            paddingLeft: `${isLevelOneHidden ?
                                (wrapUpCode.Level - 2) * 15 + 11 :
                                (wrapUpCode.Level - 1) * 15 + 11}px`,
                            cursor: "context-menu"
                        }
                    }
                );
            }
        });
        return wrapUpCodesForDisplay;
    }

    const initiateStopWrapUp = (status: PresenceAndNoteDTO) => {
        GeneralHelper.logCox('[CC4ALL-2739] being in initiateStopWrapUp');
        wrapUpStatusRef.current = WrapStatus.EndedFromTheServerSide;
        const presence: Presence = {
            Availability: status.Availability || getApiPresenceForContactCenterPresence(3000) || '',
            Activity: status.Activity || ''
        }
        socketPresence.select.next(presence);
        socketPresence.wrapUp.next(false);
    }

    const resetWrapUpSettings = () => {
        if (!wrapUpSettingsRef.current) {
            return;
        }

        wrapUpSettingsRef.current = undefined;
        setWrapUpSettings(undefined);
        Lockr.rm(StorageKeys.CurrentWrapUpCodeId);
        Lockr.rm(StorageKeys.CurrentWrapUpCodeName);
    }

    const initializeWrapUpSettings = () => {
        GeneralHelper.logCox(`[CC4ALL-2739] in initializeWrapUpSettings, entered`)
        if (!initialClientLoadService.isInitialSettingsRequestDone || !initialClientLoadService.initialSettings || initSettingsRequestCompleted.current) {
            GeneralHelper.logCox(`[CC4ALL-2739] in initializeWrapUpSettings, first return`)
            return;
        }

        initSettingsRequestCompleted.current = true;
        const typedWrapUpSettings = initialClientLoadService.initialSettings.WrapUpSettings;

        if (!initialClientLoadService.initialSettings.IsAgentInWrapUp || !typedWrapUpSettings) {
            Lockr.rm(StorageKeys.CurrentWrapUpCodeId);
            Lockr.rm(StorageKeys.CurrentWrapUpCodeName);
            GeneralHelper.logCox(`[CC4ALL-2739] in initializeWrapUpSettings, second return; IsAgentInWrapUp is ${initialClientLoadService.initialSettings.IsAgentInWrapUp} and typedWrapUpSettings is ${JSON.stringify(typedWrapUpSettings)}`)
            return;
        }

        setWrapUpSettings(typedWrapUpSettings);
        wrapUpSettingsRef.current = typedWrapUpSettings;
        socketPresence.wrapUp.next(true);
        GeneralHelper.logCox(`[CC4ALL-2739] in initializeWrapUpSettings, after socketPresence.wrapUp.next(true);`)
    }

    const initialize = () => {
        subscriptionSocketAuthUserVerified?.unsubscribe();
        subscriptionSocketAuthUserVerified = socketAuth.userVerified.subscribe((obj: any) => {
            initializeWrapUpSettings();
        });

        subscriptionInitialSettingsRequestCompleted = initialClientLoadService.notifyInitialSettingsRequestCompleted.subscribe((settings: TeamsClientInitSettingsDto) => {
            initializeWrapUpSettings();
        });

        subscriptionSocketPresenceSelect?.unsubscribe();
        subscriptionSocketPresenceSelect = socketPresence.select.subscribe((result: Presence) => {
            GeneralHelper.logCox('[CC4ALL-2739] subscriptionSocketPresenceSelect, in WrapUpExtendedView.tsx');
            if (!wrapUpSettingsRef.current) {
                GeneralHelper.logCox('[CC4ALL-2739] subscriptionSocketPresenceSelect, first return');
                return;
            }
            const wrapUpPresenceAndNoteDto: Presence = {
                Availability: getApiPresenceForContactCenterPresence(wrapUpSettingsRef.current?.Presence ?? 0) ?? '',
                Activity: wrapUpSettingsRef.current?.Reason ?? ''
            };

            const hasADifferentPresence = (result.Availability !== wrapUpPresenceAndNoteDto.Availability) ||
                (result.Activity !== wrapUpPresenceAndNoteDto.Activity) ||
                (result.Activity === 'Ringing');

            GeneralHelper.logCox(`[CC4ALL-2739] subscriptionSocketPresenceSelect, hasADifferentPresence is ${hasADifferentPresence}, result is ${JSON.stringify(result)} wrapUpStatusRef.current is ${wrapUpStatusRef.current}`);
            if ((hasADifferentPresence && result.Sip === undefined) || (wrapUpStatusRef.current !== WrapStatus.Started)) {
                result.WrapUpCodeId = Lockr.get<string>(StorageKeys.CurrentWrapUpCodeId);
                result.WrapUpCodeName = Lockr.get<string>(StorageKeys.CurrentWrapUpCodeName);
                resetWrapUpSettings();
            }
        });

        subscriptionInitializeWrapUp?.unsubscribe();
        subscriptionInitializeWrapUp = socketWrapUp.listenerInitializeWrapUp.received.subscribe((obj: ExtendedWrapUpSettings) => {
            const typedWrapUpSettings = new ExtendedWrapUpSettings(obj);
            setWrapUpSettings(typedWrapUpSettings);
            wrapUpSettingsRef.current = typedWrapUpSettings;
            socketPresence.wrapUp.next(true);
        });

        subscriptionStopWrapUp?.unsubscribe();
        subscriptionStopWrapUp = socketWrapUp.listenerStopWrapUp.received.subscribe(status => {
            initiateStopWrapUp(status);
        });
    }

    const drawTimerIfNeeded = () => {
        return (wrapUpSettingsState?.WrapupTimerVisibility && wrapUpSettingsState?.EndDate
            ? <Timer startDate={wrapUpSettingsState?.EndDate} isEndDate={true} />
            : null)
    }

    const drawWrapUpCodeDropdownIfNeeded = () => {
        const alWrapUpCodes = wrapUpSettingsState?.AllWrapUpCodesFromCollections;
        return alWrapUpCodes && alWrapUpCodes.length > 0 ?
            <Dropdown
                search
                className="wrap-codes-dropdown-extended"
                items={allWrapUpCodes}
                noResultsMessage={intl.formatMessage({ id: "WrapUp.NoResults" })}
                searchQuery={searchQuery}
                onSearchQueryChange={(e, data) => {
                    setSearchQuery(data.searchQuery ?? "");
                }}
                onOpenChange={(e: any, data) => {
                    setSearchQuery(data.open ? "" : selectedCodeQuery)
                }}
                onChange={(e, selectedOption) => {
                    if (selectedOption?.value) {
                        selectWrapUpCode((selectedOption.value as any)?.key.toString() || '');
                    }
                }}
            /> :
            null;
    }

    const selectWrapUpCode = (id: string) => {
        const newSelectedWrapUpCodes = allWrapUpCodes;
        newSelectedWrapUpCodes.forEach((element: any) => {
            const isSelected = element.key === id
            if (isSelected) {
                setSelectedCodeQuery(element.header);
                Lockr.set(StorageKeys.CurrentWrapUpCodeId, element.key);
                Lockr.set(StorageKeys.CurrentWrapUpCodeName, element.title);
                serviceRequests.ChangeWrapUpCode(element.key, element.title);
            }

            element.selected = isSelected;
        });

        setAllWrapUpCodes(newSelectedWrapUpCodes);
    }

    return wrapUpSettingsState
        ? (<Flex vAlign="center" hAlign="end" gap="gap.medium"
            styles={({ theme: { siteVariables } }) => ({
                backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
                color: siteVariables.colorScheme.default.foregroundPressed,
                padding: '0px 0px 0px 30px'
            })}
        >
            <Box content="Wrap-up" />
            {drawWrapUpCodeDropdownIfNeeded()}
            {drawTimerIfNeeded()}
        </Flex>)
        : (<></>);
};

export default WrapUpExtendedView;