export enum ServiceTypes {
    CallCenter = 'CC4Skype.CallCenterService',
    LoadBalancer = 'CC4Skype.ContactCenterloadBalancer'
}

export enum SocketTargets {
    CallCenterService = 'CC4Skype.CallCenterService',
}

export enum ListenerOperations {
    PresenceAndNoteSet = 'PresenceAndNoteSet',
    InitializeWrapUp = 'InitializeWrapUp',
    StopWrapUp = 'StopWrapUp',
    PreviousPresenceAndNoteSet = 'PreviousPresenceAndNoteSet',
    CallSessionStateChanged = 'CallSessionStateChanged',
    OnHoldStatusChanged = 'OnHoldStatusChanged',
    QueueChange = 'QueueChange',
    UpdateClientRole = 'UpdateClientRole',
    SendUrlToClient = 'SendUrlToClient',
    CompanyChanged = "CompanyChanged",
    UserRightChange = 'UserRightChange',
    OutboundEndpointsChange = 'OutboundEndpointsChange',
    OutboundCallError = 'OutboundCallError',
    WaitingMediaChange = 'WaitingMediaChange',
    ActiveMediaChange = 'ActiveMediaChange',
    UpdateOperatorCall = 'UpdateOperatorCall',
    UpdatedPresenceState = 'UpdatedPresenceState',
    ContactsChanged = 'ContactsChanged',
    RefreshClientRole = 'RefreshClientRole',
    SendNotificationToClients = 'SendNotificationToClients',
    SendSocialMediaDetailsToClients = 'SendSocialMediaDetailsToClients',
    FavoriteContactsPresenceChanged = 'FavoriteContactsPresenceChanged',
    CmMessageReceivedFromCustomer = 'CmMessageReceivedFromCustomer',

    NotifyQueueUserMembershipChange = 'NotifyQueueUserMembershipChange',
    NotifyQueueUserMembershipRemove = 'NotifyQueueUserMembershipRemove',

    CallAnsweredTooLate = 'CallAnsweredTooLate',
    AgentHasClientChanged = 'AgentHasClientChanged',
    VoicemailChanged = 'VoicemailChanged',

    OfferGenericNotification = 'OfferGenericNotification',
    AcceptGenericNotification = "AcceptGenericNotification",
    TerminateGenericNotification = "TerminateGenericNotification",
    RejectGenericNotification = "RejectGenericNotification",
    CancelGenericNotification = "CancelGenericNotification",

    AcceptGenericTransferNotification = "AcceptGenericTransferNotification",
    WaitingGenericChange = "WaitingGenericChange",
}

//#warning-js [cc4all-1102]: separate operation enums from other types of enums

export enum UserAccessRightsTypes {
    Record = 1,
    JoinLeave = 2,
    RealTime = 3
}

export enum StorageKeys {
    UPN = 'userPrincipalName',
    UPNForAzure = 'userPrincipalNameForAzure',
    UserName = 'userName',
    TokenId = 'tokenId',
    SIP = 'SIP',
    CompanyId = 'companyId',
    CompanyKey = 'companyKey',
    UserId = 'userId',
    UserObjectId = 'userObjectId',
    TokenAD = 'tokenAD',
    TokenADDelegated = 'tokenADDelegated',
    FullName = 'fullName',
    SuggestedContactsIds = 'suggestedContactsIds',
    ChatHistory = 'chatHistory',
    Language = 'language',
    OutboundEndpoint = 'outboundEndpoint',
    OnlyOnlineAgents = 'OnlyOnlineAgents',
    ExternalProcessed = 'externalProcessed',
    AlertMessage = 'alertMessage',
    SupervisorQueueSelected = 'supervisorQueueSelected',
    SupervisorCallsFilters = 'supervisorCallsFilters',
    ServerClientTimeDifference = 'serverClientTimeDifference',
    AzureADLoginType = 'azureLoginType',
    TIDForAzure = 'tid',
    ServiceCallTimeout = 'serviceCallTimeout',
    Exception = 'exception',
    IsCustomerJourneyEnabled = 'isCustomerJourneyEnabled',
    SessionExpiryTimestamp = 'sessionExpiryTimestamp',
    UserwayKey = 'UserwayKey',
    ExtendedMediaActiveTab = 'extendedMediaActiveTab',
    GenericActiveTab = 'genericActiveTab',
    CurrentWrapUpCodeId = 'currentWrapUpCodeId',
    CurrentWrapUpCodeName = 'currentWrapUpCodeName',
    MainViewTab = 'MainViewTab',
    LastActiveGenericId = 'LastActiveGenericId',
    BrowserSessionId = 'browserSessionId',
    GenericMediaActiveTab = 'GenericMediaActiveTab',
    DeploymentTenantKey = 'td',
    DeploymentTenantId = 'tdid',
    IsNewClientEnabled = 'IsNewClientEnabled'
}

export enum ActiveMainViewItem {
    MediaView = 0,
    WebChatView,
    CallHistoryView,
    VoiceMailsView,
    RealtimeReportsView,
    SocialMedia,
    CustomerJourneyView,
    CmMedia
}

export enum ChatMessageType {
    Text = 0,
    Attachment,
    PrivateMessage,
    BusinessRule
}

export enum ChatMessageSource {
    Visitor = 0,
    Agent,
    Server
}

export enum ChatStatusType {
    Incoming = 0,
    Ringing,
    Abondoned,
    Waiting,
    Accepted,
    Ignored,
    TransferToAgentInviteSent,
    TransferToAgentInviteRejected,
    TransferToAgentInviteAccepted,
    TransferedToQueue,
    Terminated,
    ScheduleClose,
    CallCenterStopped,
    RefusedByCallCenter,
    AcceptedByCallCenter,
    Removed
}

export enum HandlingStatus {
    UnAssigned = 0,
    Ringing,
    InCall,
    WarmRequested,
    WarmAgent,
    WarmCaller,
    Terminated,
    OnHold,
    Resumed,
    OperatorColdTransferInviteSent,
    OperatorColdTransferInviteAccepted,
    OperatorColdTransferInviteRejected,
    OperatorColdTransferInviteCancelled,
    PersonalParked,
    QueueWarmTransferStarted,
    QueueWarmTransferCancelled,
    QueueWarmTransferCommited,
    QueueWarmTransferAcceptedBySecondAgent,
    QueueWarmTransferSwitchedToCaller,
    QueueWarmTransferSwitchedToAgent,
    QueueColdTransferToCC4SQueue,
    AgentColdTransfered
}

export enum HttpMethod {
    Get = "get",
    Post = "post",
    Delete = "delete",
    Put = "put"
}

export enum NotificationHubOperations {
    UpdatedPresenceState = 'UpdatedPresenceState',
    SubscribeToNotificationHub = 'SubscribeToNotificationHub',
    UnSubscribeToNotificationHub = 'UnSubscribeToNotificationHub',
    ContactsChanged = "ContactsChanged",
    NotifySignedInAnotherView = "NotifySignedInAnotherView"
}

export enum PresenceAvailability {
    None = 0,
    Online = 3000,
    IdleOnline = 4500,
    Busy = 6000,
    IdleBusy = 7500,
    DoNotDisturb = 9000,
    BeRightBack = 12000,
    Away = 15000,
    Offline = 18000
}

export enum CallOperation {
    MakeCall = "makeCall",
    Raise = "raise"
}

export enum UserChangeType {
    Added = 1,
    Updated = 2,
    Deleted = 3
}

export enum ConferenceStatusAction {
    Accepted = 0,
    CallerClosedBeforeAnswer = 1,
    CallerClosedAfterAnswer = 2,
    SupervisorClosed = 3,
    AgentClosed = 4,
    OnHold = 5,
    Terminated = 6,
    Offering = 7,
    Ignored = 8,
    Resumed = 9,
    Monitoring = 10,
    PickedUp = 11,
    WarmInviteSent = 12,
    WarmInviteAccepted = 13,
    WarmInviteRejected = 14,
    WarmAccepted = 15,
    WarmCanceled = 16,
    WarmSwitchToCaller = 17,
    WarmSwitchToAgent = 18,
    OperatorPark = 19,
    OperatorHold = 20,
    OperatorColdTransfered = 21,
    OperatorColdTransferred = -21,
    OperatorColdTransferInviteSent = 22,
    OperatorColdTransferInviteAccepted = 23,
    OperatorColdTransferInviteRejected = 24,
    OperatorColdTransferInviteCancelled = 25,
    Fallback = 26,
    SimultaniousIgnored = 27,
    OperatorInterruptEnded = 28,
    CallerAudioAddedToConference = 29,
    TrustedParticipantAudioAdded = 30,
    TrustedParticipantForAutoRecordingAdded = 31,
    TrustedParticipantForManualRecordingAdded = 32,
    TrustedParticipantForVoiceMailRecordingAdded = 33,
    TrustedParticipantAudioForAgentsAdded = 34,
    ConferenceReceivedFromPool = 35,
    NewConferenceCreated = 36,
    PersonalParked = 37,
    MonitoringEnded = 38,
    QueueWarmTransferStarted = 39,
    QueueWarmTransferCommited = 40,
    QueueWarmTransferCancelled = 41,
    QueueWarmTransferAcceptedBySecondAgent = 42,
    QueueWarmTransferSwitchedToCaller = 43,
    QueueWarmTransferSwitchedToAgent = 44,
    QueueColdTransferToCC4SQueue = 45,
    AgentColdTransfered = 46,
    InviteAccepted = 47,
    CallerMuted = 48,
    CallerUnmuted = 49,
    WarmCanceledBySecondAgent = 50,
    Started = 51,
    CallRouted = 52,
    MonitoringStarting = 53,
    OperatorIVRColdTransferred = 54,
    Ringing = 55,
    Unavailable = 56,
    OutboundAcceptedByAgent = 57
}

export enum MediaType {
    Call = 0,
    Mail = 1,
    Instant = 2,
    Chat = 3,
    SocialMedia = 4
}

export enum QueueMediaStatusType {
    Waiting = 0,
    Offering = 1,
    Assigned = 2,
    ColdTransferRinging = 3,
    ColdTransfer = 4,//after cold transfer finalized
    WarmTransferRinging = 5,
    WarmTransferInitialized = 6,//after agent2 answers warm transfer
    WarmTransfer = 7,//after warm transfer finalized
    Parked = 8,
    FallBack = 9,
    CallTransferIVR = 10,
    CallbackRequest = 11,
    CallbackOffering = 12
}

export enum OperatorCallState {
    None = 0,
    UnAssigned,
    AssignedToOperator,
    BeforeAssignedToOperator,
    AssignedToOperatorInWarmTransfer,
    Parked,
    AssignedToAgentInCall,
    AssignedToAgentRinging,
    AssignedToAgentInPersonalQueue,
    OperatorInterrupt,
    FallBack,
    OnHold,
    BeforeExternalColdTransfer,
    Offering,
    QueueColdTransferToQueue
}

export enum MediaState {
    Waiting = "Waiting",
    Assigned = "Assigned",
    Transferring = "Transferring",
    Parked = "Parked",
    AgentHandling = "Agent Handling",
    AgentRinging = "Agent Ringing",
    AgentWaiting = "Agent Waiting",
    Fallback = "Fallback",
    OnHold = "On Hold",
    Offering = "Offering",
}

export enum ReasonNameConstants {
    Ringing = "Ringing",
    InACall = "In a call",
    Default = "",
    Online = "Online",
    Available = "Available",
    Busy = "Busy",
    DND = "Do not disturb",
    BeRightBack = "Be right back",
    Away = "Away",
    Offline = "Offline",
    Unavailable = "Unavailable",
    Offering = "Offering"
}

export enum MenuActiveItem {
    Default,
    AgentView,
    ExternalTab,
    CallHistory,
    ExternalLink,
    Supervisor,
    VoiceMailAlerts,
    VoiceMailVoiceMails,
    GenericAgent,
    GenericHistory,
    Email
}

export enum TabViewIds {
    Email = -8,
    GenericHistory = -7,
    GenericAgent = -6,
    VoiceMailVoiceMails = -5,
    VoiceMailAlerts = -4,
    Supervisors = -3,
    Agent = -2,
    CallHistory = -1
}

export enum NotificationType {
    INFO,
    WARNING,
    ALERT
}

export enum NotificationMethodType {
    Client = 0,
    Mail,
    ClientandMail,
    Screen,
    ScreenandMail
}

export enum NotificationDataType {
    Alert,
    VoiceMail
}

export enum NotificationActionStatus {
    Added,
    Removed,
    Updated
}

export enum MonitorTypes {
    None = 0,
    Silent = 1,
    Whisper,
    BargeIn,
    Ringing,
    BySomeoneElse = 100
}

export enum BotNotificationType {
    Webchat = "webchat",
    Alert = "alerts",
    VoiceMail = "voicemails",
    MissedCall = "missedCall"
}

export enum WrapStatus {
    Started = "Started",
    Ended = "Ended",
    EndedFromTheServerSide = "EndedFromTheServerSide"
}

export enum SocialMediaStatusType {
    Incoming = 0,
    ScheduleWait = 1,
    Ringing = 2,
    Waiting = 3,
    Accepted = 4,
    Ignored = 5,
    Terminated = 6,
    CallCenterStarted = 7,
    CallCenterStopped = 8
}

export enum SocialMediaOperations {
    SocialMediaAccept = 'SocialMediaAccept',
    SocialMediaIgnore = 'SocialMediaIgnore',
    SocialMediaTerminate = 'SocialMediaTerminate',
    GetActiveSocialMediaSessions = 'GetActiveSocialMediaSessions',
    GetRingingSocialMediaSessions = 'GetRingingSocialMediaSessions',
    GetWebHookSetting = 'GetWebHookSetting',
    GetActiveCmMediaConversationHistory = 'GetActiveCmMediaConversationHistory',
    SendCmMediaMessage = 'SendCmMediaMessage'
}

export enum WebhookProvider {
    Tracebuzz = 0,
    Obi4Wan = 1
}

export enum HeaderBarDisplayedMessageType {
    MediaView = 0,
    SocialMedia
}

export enum ErrorColor {
    Red = "Red",
    Grey = "Grey"
}

export enum AzureLoginType {
    None = 0,
    SSO = 1,
    TeamsProvider = 2
}

export enum CustomerJourneyOperation {
    GetCustomer = 'GetSelectedJourneyCustomerList',
    GetCustomersByName = 'GetCustomerByName',
    SaveCustomer = "PostCustomer",
    PostCustomerAndSession = "PostCustomerAndSession",
    IdentifyCaller = "GetIdentifyCaller",
    GetCustomerSession = 'GetCustomerSession',
    PostCustomerSession = 'PostCustomerSession',
    PostConversationNote = 'PostConversationNote',
    GetConversationNotes = 'GetConversationNotes'
}


export enum UserTypeStage3 {
    Database,
    AzureActiveDirectory,
    Office365
}

export enum CalendarSensitivity {
    Normal = "normal",
    Personal = "personal",
    Private = "private",
    Confidential = "confidential",
}

export enum CallableContactColumnsEnum {
    Sip = "Sip",
    LocalPhone = "LocalPhone",
    BusinessPhones = "BusinessPhones",
    MobilePhone = "MobilePhone",
    QueueName = "QueueName"
}

export enum SortDirection {
    Asc = 1,
    Desc = 2
}

export enum ContactColumns {
    DisplayName = "DisplayName",
    LocalPhone = "LocalPhone",
    Mail = "Mail",
    Email = "Email",
    Upn = "Upn",
    BusinessPhones = "BusinessPhones",
    MobilePhone = "MobilePhone",
    JobTitle = "JobTitle",
    Department = "Department",
    City = "City",
    OfficeLocation = "OfficeLocation",
    Country = "Country",
    State = "State",
    PostalCode = "PostalCode",
    UserPrincipalName = "UserPrincipalName"
}

export enum SortableColumnValue {
    ContactsDisplayName = 'ContactsDisplayName',
    ContactsMail = 'ContactsMail',
    ContactsJobTitle = 'ContactsJobTitle',
    ContactsDepartment = 'ContactsDepartment',
    ContactsCity = 'ContactsCity',
    ContactsOfficeLocation = 'ContactsOfficeLocation',
    ContactsCountry = 'ContactsCountry',
    ContactsState = 'ContactsState',
    ContactsPostalCode = 'ContactsPostalCode'
}

export enum ContactSipAdressColumnsEnum {
    Upn = "Upn",
    Mail = "Mail"
}

export enum UserResetStatus {
    ResetPerformed = "ResetPerformed",
    CallInProgress = "CallInProgress",
    DndStatusIsNotAllowed = "DndStatusIsNotAllowed",
}

export enum QueueMediaOperations {
    Added = 'Added',
    Updated = 'Updated',
    Removed = 'Removed'
}

export enum JourneyCustomerConversationAction {
    QueueEntered = 0,
    CallOffered,
    CallAnswered,
    CallWarmTransferInitiated,
    CallWarmTransferCompleted,
    CallWarmTransferCancelled,
    CallColdTransferred,
    CallEnded,
    CallParked,
    CallUnparked,
    CallRequeued
}

export enum QueueMediaViewType {
    Waiting = 1,
    Supervisor = 2
}

export enum SharedStyleClasses {
    TabUnderline = "tab-underline"
}

export enum Colors {
    Red = "red",
    Gray = "gray",
    Green = "green"
}

export enum Desire {
    High = 1,
    Normal = 2,
    Low = 3
}
