import { StorageKeys, ConferenceStatusAction, MediaType, Controllers, logging, Logger, ChatMessageSource, ChatMessageType, ChatStatusType } from "utils";
import Lockr from 'lockr';
import { QueueUserDTO } from "utils/domain/queueUserDTO";
import { ConfigurationHelper, EnvHelper } from ".";
import { FavoriteContactType } from "utils/domain/favoriteContactDTO";
import { ContactType } from "utils/domain/transfercall";
import { InvokeServiceArgs } from "services/io/interfaces";
import { serviceCall } from "services/io/service-call";
import { CMMediaSessionMessageDTO, CMSourceType } from "utils/domain/socialMedia/socialMediaSessionDTO";
import { ChatHistoryDTO } from "utils/domain/chathistoryDTO";
import { ChatSessionDTO } from "utils/domain/chatSessionDTO";
import { SocialMediaRequestDTO } from "utils/domain/socialMedia/socialMediaRequestDTO";
import React from "react";
import { AcceptIcon, WindowMinimizeIcon } from "@fluentui/react-northstar";

export default class GeneralHelper {
    public static formatAMPM(date: any, convertUtcToLocal: boolean = false) {
        if (convertUtcToLocal && date) {
            date = date[date.length - 1] === 'Z' ? new Date(date) : new Date(`${date}Z`);
        }

        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const month = date.getMonth() + 1;
        const day = date.getDate();
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return this.getCultureFormat(day, month, hours, minutes, ampm);
    }

    private static readonly getCultureFormat = (day: number, month: number, hours: number, minutes: number, ampm: string) => {
        const culture = Lockr.get<string>(StorageKeys.Language);

        let strTime: string;
        switch (culture) {
            case "en-us":
                strTime = `${month}/${day} ${hours}:${minutes} ${ampm}`;
                break;
            case "en-en":
                strTime = `${month}/${day} ${hours}:${minutes} ${ampm}`;
                break;
            case "nl-nl":
                strTime = `${day}-${month} ${hours}:${minutes} ${ampm}`;
                break;
            case "ro-ro":
                strTime = `${day}.${month} ${hours}:${minutes} ${ampm}`;
                break;
            default:
                strTime = `${day}/${month} ${hours}:${minutes} ${ampm}`;
                break;
        }
        return strTime;
    }

    public static getEndOperations = () => [//this is for the call session of the current agent, the call is not necessarily ended, might be transferred
        ConferenceStatusAction[ConferenceStatusAction.Terminated],
        ConferenceStatusAction[ConferenceStatusAction.AgentClosed],
        ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer],
        ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer],
        ConferenceStatusAction[ConferenceStatusAction.AgentColdTransfered],
        ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered],
        ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred],
        ConferenceStatusAction[ConferenceStatusAction.WarmAccepted],
        ConferenceStatusAction[ConferenceStatusAction.QueueColdTransferToCC4SQueue],
        ConferenceStatusAction[ConferenceStatusAction.Ignored],
        ConferenceStatusAction[ConferenceStatusAction.Unavailable]
    ];

    public static getOngoingCallOperations = () => [
        ConferenceStatusAction[ConferenceStatusAction.Accepted],
        ConferenceStatusAction[ConferenceStatusAction.Ringing],
        ConferenceStatusAction[ConferenceStatusAction.OnHold],
        ConferenceStatusAction[ConferenceStatusAction.Monitoring],
        ConferenceStatusAction[ConferenceStatusAction.MonitoringEnded],
        ConferenceStatusAction[ConferenceStatusAction.SupervisorClosed],
        ConferenceStatusAction[ConferenceStatusAction.Resumed],
        ConferenceStatusAction[ConferenceStatusAction.WarmInviteSent],
        ConferenceStatusAction[ConferenceStatusAction.WarmInviteAccepted],
        ConferenceStatusAction[ConferenceStatusAction.WarmCanceled],
        ConferenceStatusAction[ConferenceStatusAction.WarmSwitchToAgent],
        ConferenceStatusAction[ConferenceStatusAction.WarmSwitchToAgent],
        ConferenceStatusAction[ConferenceStatusAction.WarmAccepted]
    ];

    public static getStage3EndOperations = () => [
        ConferenceStatusAction[ConferenceStatusAction.Terminated],
        ConferenceStatusAction[ConferenceStatusAction.AgentClosed],
        ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer],
        ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer],
        ConferenceStatusAction[ConferenceStatusAction.AgentColdTransfered],
        ConferenceStatusAction[ConferenceStatusAction.OperatorIVRColdTransferred],
        ConferenceStatusAction[ConferenceStatusAction.WarmAccepted],
        ConferenceStatusAction[ConferenceStatusAction.QueueColdTransferToCC4SQueue],
        ConferenceStatusAction[ConferenceStatusAction.Ignored],
        ConferenceStatusAction[ConferenceStatusAction.Unavailable]
    ];

    public static getStage3StartOperations = [
        ConferenceStatusAction[ConferenceStatusAction.Accepted],
        ConferenceStatusAction[ConferenceStatusAction.Offering],
        ConferenceStatusAction[ConferenceStatusAction.OnHold],
        ConferenceStatusAction[ConferenceStatusAction.Resumed]
    ];

    public static getAttachedUserQueues = (queues: QueueUserDTO[]) => {
        const attachedQueues = [] as number[];

        queues.forEach((queue: QueueUserDTO) => {
            if (queue.IsAttached) {
                attachedQueues.push(queue.QueueRef);
            }
        });

        return attachedQueues;
    }


    public static getMobileNumberFromSip = (sip: string) => {
        if (!sip) {
            return "";
        }
        return sip.replace("tel:", "").replace("sip:", "").split("@")[0];
    }

    public static getMobileNumberFromString = (phone: string) => {
        if (!phone) {
            return "";
        }
        return phone.replace(/(?![0-9]|[+*#])./g, "");
    }

    public static isMobileNumber = (sip: string) => {
        return /^(\d|\+|#)+$/.test(GeneralHelper.getMobileNumberFromSip(sip));
    }

    public static formatSource = (source: string | undefined) => {
        return source && GeneralHelper.isMobileNumber(source) ? GeneralHelper.getMobileNumberFromSip(source) : source;
    }

    public static formatSipOrPhoneNumber = (phone: string) => {
        if (phone.includes("@") && phone.split("@")[1].includes(".")) {
            if (GeneralHelper.isMobileNumber(phone)) {
                return GeneralHelper.getMobileNumberFromSip(phone);
            }
            return phone.replace("tel:", "");
        }
        if (phone.length > 0) {
            if (GeneralHelper.hasNumbers(phone)) {
                return GeneralHelper.getMobileNumberFromString(phone);
            }
            return phone;
        }
        return "";
    }

    public static hasNumbers = (value: string) => {
        return /\d/.test(value);
    }

    public static removeSipFromName = (name: string) => {
        return name ? name.replace("sip:", "") : name;
    }

    private static _isCox: boolean | null = null;
    public static isCox = () => {
        if (GeneralHelper._isCox !== null) {
            return GeneralHelper._isCox;
        }
        const socketServerDomain = ConfigurationHelper.socketServerDomain;
        const isCoxServer = (socketServerDomain.toLowerCase()?.indexOf("DESKTOP-72".toLowerCase()) > -1);
        const isCoxOnLocalStorage = Lockr.get<boolean>("isCox");
        GeneralHelper._isCox = isCoxServer || isCoxOnLocalStorage;
        return GeneralHelper._isCox;
    }

    public static logCox = (message: string) => {
        if (GeneralHelper.isCox()) {
            console.log('cox: ' + message);
        }
    }

    public static mapFavoriteTypeToContactType = (favoriteType: FavoriteContactType) => {
        switch (favoriteType) {
            case FavoriteContactType.ActiveDirectory:
            case FavoriteContactType.Agent:
            case FavoriteContactType.DbContact:
            case FavoriteContactType.Federated:
            case FavoriteContactType.Office365:
            case FavoriteContactType.AzureActiveDirectory: return ContactType.Agent;
            case FavoriteContactType.ApplicationQueue: return ContactType.ApplicationQueue;
            case FavoriteContactType.CommonAreaPhone: return ContactType.CommonAreaPhone;
            case FavoriteContactType.ResponseGroup: return ContactType.ResponseGroup;
            case FavoriteContactType.NonCC4SkypeApplication: return ContactType.NonCC4SkypeApplication;
            case FavoriteContactType.CC4SkypeQueue: return ContactType.CC4SkypeQueue;
            case FavoriteContactType.ExchangeUMContact: return ContactType.ExchangeUMContact;
            default: return "";
        }
    }

    public static isStringEmptyOrWhiteSpace = (stringToEval: string) => {
        return /\S/.test(stringToEval);
    }

    public static isCallParkedOrFallback = (callHistory: number[], status: ConferenceStatusAction) => {
        if (!callHistory || callHistory.length < 2) {
            return false;
        }
        const length = callHistory.length;
        const lastElement = callHistory[length - 1];
        const secondToLast = callHistory[length - 2];

        if (lastElement === status ||//when the call is first being put on hold, and afterwards parked
            (secondToLast === status && !EnvHelper.isStage3() )) {
            return true;
        }

        if (length < 3) {
            return false;
        }

        const thirdToLast = callHistory[length - 3];

        if ((secondToLast === ConferenceStatusAction.Offering && thirdToLast === status && lastElement === ConferenceStatusAction.OnHold) ||
            (thirdToLast === status && secondToLast === ConferenceStatusAction.OnHold && lastElement === ConferenceStatusAction.Resumed) ||
            (thirdToLast === status && secondToLast === ConferenceStatusAction.Resumed && lastElement === ConferenceStatusAction.OnHold)) {
            return true;
        }

        return false;
    }

    public static isRinging = (callHistory: number[]) => {
        const ringingOperationStage2 = [
            ConferenceStatusAction.Offering,
            ConferenceStatusAction.OperatorColdTransfered,
            ConferenceStatusAction.AgentColdTransfered
        ];

        const ringingOperationStage3 = [
            ConferenceStatusAction.Offering,
            ConferenceStatusAction.Offering,
            ConferenceStatusAction.OperatorColdTransfered,
            ConferenceStatusAction.AgentColdTransfered,
            ConferenceStatusAction.OperatorIVRColdTransferred,
            ConferenceStatusAction.WarmInviteSent,
            ConferenceStatusAction.WarmInviteAccepted,
            ConferenceStatusAction.OutboundAcceptedByAgent
        ];
        
        const ringingOperations = EnvHelper.isStage3() ? ringingOperationStage3 : ringingOperationStage2;

        if (!callHistory || callHistory.length < 1) {
            return false;
        }

        return ringingOperations.includes(callHistory[callHistory.length - 1]);
    }

    public static getMediaTypeContainerClassName = (mediaType?: MediaType): string => {
        const className = "smallest-grow";

        if (mediaType === MediaType.SocialMedia) {
            return className + " social-media-icon-container"
        }

        return className;
    }

    public static callerHasLeft = (actionHistory: string[] | undefined, action: string, isPrimaryAgent: boolean) => {
        if (action === ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer]) {
            return true;
        }
        return actionHistory !== undefined && (
            GeneralHelper.IsColdTransferLeave(actionHistory) ||
            GeneralHelper.IsWarmTransferLeave(actionHistory, action, isPrimaryAgent) ||
            GeneralHelper.IsUnparkLeave(actionHistory, action));
    }

    public static isCalendarEnabled = () => {
        const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);

        if (!companyKey) {
            return false;
        }

        const tenantsCalendarAccess = (window as any)['config'] ? (window as any)['config'].userCalendarAccess : undefined;

        if (!tenantsCalendarAccess) {
            return true;
        }

        const isAccessEnabled = tenantsCalendarAccess[companyKey];
        return isAccessEnabled !== false;
    }

    public static isCalendarRestricted = () => {
        const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);

        if (!companyKey) {
            return true;
        }

        const tenantsCalendarRestricted = (window as any)['config'] ? (window as any)['config'].userCalendarRestricted : undefined;

        if (!tenantsCalendarRestricted) {
            return true;
        }

        const isCalendarRestricted = tenantsCalendarRestricted[companyKey];

        return isCalendarRestricted !== false;
    }

    public static invokeServiceCall = (
        data: any,
        operation: string,
        logger: Logger,
        controller: string = Controllers.CallCenter,
        fetchApiUrl: string = ConfigurationHelper.gatewayApiUrl,
        sender: string = Lockr.get(StorageKeys.SIP),
        httpMethod: string = ''): Promise<any> => {

        if (!controller) {
            controller = Controllers.CallCenter
        }
        if (!fetchApiUrl) {
            fetchApiUrl = ConfigurationHelper.gatewayApiUrl
        }
        if (!sender) {
            sender = Lockr.get(StorageKeys.SIP)
        }

        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                sender: sender,
                operation: operation,
                controller: controller,
                fetchApiUrl: fetchApiUrl,
                requestData: data,
                httpMethod: httpMethod,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.Offline");
                        logger.error(err);
                        reject(err);
                    }
                }
            };

            return serviceCall.invokeService(args);
        });
    }

    public static mapCmMessagesToChatHistoryList(cmMessages: CMMediaSessionMessageDTO[], agentSip: string) {
        const chatHistory: ChatHistoryDTO[] = [];
        cmMessages.forEach(message => {
            let messageText = message.MessageText ? message.MessageText : message.MediaTitle;
            messageText = messageText || '';

            const chatHistoryMessage: ChatHistoryDTO = {
                Id: message.Reference,
                Message: messageText,
                MessageOwner: '',
                ChatSessionRef: '',
                AgentSIP: agentSip,
                Source: message.SourceType === CMSourceType.FromAgent ? ChatMessageSource.Agent : ChatMessageSource.Visitor,
                Type: ChatMessageType.Text,
                AttachmentRef: '',
                AttachmentURL: '',
                AttachmentFileName: '',
                AttachmentType: '',
                AttachmentSize: 0,
                Date: message.Time || new Date(),
                Name: message.FromName || message.FromNumber || '',
                CmStatusCode: message.StatusCode
            };
            chatHistory.push(chatHistoryMessage);
        });

        return chatHistory;
    }

    public static mapCmMessagesToChatSessionDTO = (cmMessages: CMMediaSessionMessageDTO[], activeSessionId: string) => {
        if (cmMessages?.length) {
            const cmCaller = cmMessages[0].FromName || cmMessages[0].FromNumber;
            const startDate = cmMessages[0].Time || new Date();
            const agentSip = Lockr.get<string>(StorageKeys.SIP);

            const chatHistory: ChatHistoryDTO[] = GeneralHelper.mapCmMessagesToChatHistoryList(cmMessages, agentSip);

            const chatSession: ChatSessionDTO = {
                QueueName: "",
                QueueRef: 0,
                SessionRef: activeSessionId,
                StartDate: startDate,
                ConnectionId: '',
                ChatHistories: chatHistory,
                TargetAgentSIP: '',
                VisitorPreChatFormDictionary: { CustomerName: cmCaller },
                VisitorIP: '',
                VisitorHOST: '',
                VisitorCountryCode: '',
                VisitorCountryName: '',
                VisitorRegionCode: '',
                VisitorRegionName: '',
                VisitorCity: '',
                VisitorZipCode: '',
                VisitorTimeZone: '',
                VisitorLatitude: '',
                VisitorLongitude: '',
                VisitorMetroCode: '',
                VisitorBrowser: '',
                VisitorOS: '',
                IsTransfer: false,
                TransferMessage: '',
                HasHistory: false,
                ChatStatusType: ChatStatusType.Accepted,
                ChatStatusTypeBeforeRemove: ChatStatusType.Ringing
            };
            return [chatSession];
        }
        return [];
    }

    public static getSocialMediaRequestDto = (sessionId: string): SocialMediaRequestDTO => {
        const socialMediaRequest = new SocialMediaRequestDTO();
        socialMediaRequest.TargetSip = Lockr.get<string>(StorageKeys.SIP);
        socialMediaRequest.SessionId = sessionId;

        return socialMediaRequest;
    }

    public static findIndexByAttr = (array: any[], attr: any, value: any) => {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    private static readonly IsColdTransferLeave = (actionHistory: string[]) => {
        if (actionHistory.length >= 2 && (
            actionHistory[actionHistory.length - 2] === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] ||
            actionHistory[actionHistory.length - 2] === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred] ||
            actionHistory[actionHistory.length - 2] === ConferenceStatusAction[ConferenceStatusAction.AgentColdTransfered] ||
            actionHistory[actionHistory.length - 2] === ConferenceStatusAction[ConferenceStatusAction.OperatorIVRColdTransferred]) &&
            actionHistory[actionHistory.length - 1] === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer]) {
            return true;
        }
        return false;
    }

    private static readonly IsWarmTransferLeave = (actionHistory: string[], action: string, isPrimaryAgent: boolean) => {
        if (EnvHelper.isStage3()) {
            return false;
        }

        if ((action === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
            action === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]) &&
            !isPrimaryAgent &&
            actionHistory[actionHistory.length - 2] !== ConferenceStatusAction[ConferenceStatusAction.WarmInviteAccepted]) {
            return true;
        }

        if (actionHistory.length >= 3 &&
            actionHistory[actionHistory.length - 3] === ConferenceStatusAction[ConferenceStatusAction.WarmInviteSent] &&
            actionHistory[actionHistory.length - 2] === ConferenceStatusAction[ConferenceStatusAction.OnHold] &&
            actionHistory[actionHistory.length - 1] === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer]) {
            return true;
        }
        return false;
    }

    private static readonly IsUnparkLeave = (actionHistory: string[], action: string) => {
        if (action === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer] &&
            (actionHistory.includes(ConferenceStatusAction[ConferenceStatusAction.OperatorPark]) ||
                actionHistory.includes(ConferenceStatusAction[ConferenceStatusAction.Fallback])) &&
            actionHistory[actionHistory.length - 2] !== ConferenceStatusAction[ConferenceStatusAction.Accepted]
        ) {
            return true;
        }
        if (actionHistory.length >= 4 && actionHistory[actionHistory.length - 4] === ConferenceStatusAction[ConferenceStatusAction.OperatorPark] &&
            actionHistory[actionHistory.length - 2] === ConferenceStatusAction[ConferenceStatusAction.Offering] &&
            actionHistory[actionHistory.length - 1] === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer]) {
            return true;
        }
        return false;
    }

    public static checkIsRealtimeReportsAccessGrantedForAgent = (queues: QueueUserDTO[]) => {
        let isAccessGranted = false;

        queues.forEach((queue: QueueUserDTO) => {
            queue.AccessRights.UserAccessRights.forEach(accessRight => {
                if (accessRight.IsGranted && accessRight.QueueOperationName === 'Realtime') {
                    isAccessGranted = true;
                }
            });
        });

        return isAccessGranted;
    }

    public static getInitials = (name: string) => {
        const splitBySpace = name.trim().split(" ");
        let initials = '';

        while (splitBySpace.indexOf("") > -1) {
            splitBySpace.splice(splitBySpace.indexOf(""), 1);
        }

        for (let i = 0; i < 2; i++) {
            if (splitBySpace.length > i) {
                initials += splitBySpace[i][0];
            }
        }

        return initials;
    }

    public static getStatus = (availability: string, intl: any) => {
        switch ((availability ?? '').toLowerCase()) {
            case 'online':
            case 'idleonline':
            case 'available':
            case 'availableidle': return {
                styles: ({ theme: { siteVariables } }: any) => ({
                    backgroundColor: siteVariables.colorScheme.green.background
                }),
                icon: <AcceptIcon styles={({ theme: { siteVariables } }) => ({
                    color: siteVariables.colorScheme.default.borderFocusWithin,
                })}
                />,
                title: GeneralHelper.getStatusText(availability, intl),
            };
            case 'away':
            case 'berightback': return {
                icon: <img alt='Away Icon' />,
                title: GeneralHelper.getStatusText(availability, intl),
                className: 'away-icon'
            };
            case 'busy':
                return {
                    color: '#c4314b',
                    title: GeneralHelper.getStatusText(availability, intl),
                };
            case 'offline':
            case 'offwork':
            case 'none':
                return "unknown";
            case '':
            case 'presenceunknown':
            case 'unknown':
                return null;
            case 'donotdisturb':
            default: return {
                styles: ({ theme: { siteVariables } }: any) => ({
                    backgroundColor: siteVariables.colorScheme.red.background
                }),
                icon: <WindowMinimizeIcon styles={({ theme: { siteVariables } }) => ({
                    color: siteVariables.colorScheme.default.borderFocusWithin,
                })}
                />,
                title: GeneralHelper.getStatusText(availability, intl),
            };
        }
    }

    public static getStatusText = (availability: string, intl: any) => {
        switch ((availability ?? '').toLowerCase()) {
            case '':
            case 'presenceunknown':
            case null:
                return '';
            case 'available':
            case 'online':
                return intl.formatMessage({ id: "Status.Available" });
            case 'availableidle':
            case 'idleonline':
                return intl.formatMessage({ id: "Status.AvailableIdle" });
            case 'donotdisturb':
                return intl.formatMessage({ id: "Status.DND" });
            case 'busy':
                return intl.formatMessage({ id: "Status.Busy" });
            case 'offline':
            case 'offwork':
            case 'none':
                return intl.formatMessage({ id: "Status.Offline" });
            case 'away':
                return intl.formatMessage({ id: "Status.Away" });
            case 'berightback':
                return intl.formatMessage({ id: "Status.BRB" });
            case 'inacall':
                return intl.formatMessage({ id: "Status.InACall" });
            case 'ringing':
                return intl.formatMessage({ id: "Status.Ringing" });
            case 'offering':
                return intl.formatMessage({ id: "Status.Offering" });
            default:
                return availability;
        }
    }

    public static formatHourAMPM(date: Date) {
        let hours = date.getHours();
        let minutes = "" + date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';

        if (hours !== 0) {
            hours = hours % 12;
            hours = hours ? hours : 12;
        }

        minutes = +minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`;
    }

    public static formatHourAMPMFromStringInput(dateAsString: string, convertFromUtcToBrowserTime: boolean = false) {
        if (convertFromUtcToBrowserTime) {
            dateAsString = dateAsString[dateAsString.length - 1] === 'Z' ? dateAsString : `${dateAsString}Z`;
        }

        const date = new Date(dateAsString);

        return GeneralHelper.formatHourAMPM(date);
    }
}
